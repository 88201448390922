import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import 'dayjs/locale/th'

const client = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: new HttpLink({
    uri: process.env.WORDPRESS_API_URL,
  }),
  cache: new InMemoryCache(),
})

export default client
